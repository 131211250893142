import firebase from '../firebase'
import { setGuestStatus } from './guest'
import { SET_BOOK, CLEAR_BOOK, } from '../actions'
import { verifyAge, verifyLength } from './verifyfuncs'
import errorLog from '../errorLog'
import defaultIdPhoto from 'assets/img/passport.png'
import defaultFacePhoto from 'assets/img/placeholder.jpg'
import { setImagePath } from './photo'


export const readBook = (guest, dispatch) => {
  if (guest.id.length < 6)
    return

  //console.log('readBook', guest.hostID, guest.bookID)
  //指定idのbookをコレクションから読み込む
  firebase.firestore().collection('hosts').doc(guest.hostID)
          .collection('books').doc(guest.bookID).get()
    .then(function(doc) {
      if (!(doc.exists)) {
        console.error('####!指定のbookが存在しない:', guest.bookID)
        errorLog('guest-no-book-id', guest.bookID)
        dispatch({type: CLEAR_BOOK})
      }
      else {
        console.log('####got_book', doc.data());
        let book = doc.data();
        book.id = guest.bookID;
        prepareGuestMember(book)
        dispatch({type: SET_BOOK, book: book})
        setPhotoUrl(guest, book, dispatch)
        if (guest.status === 'registerd' && firebase.auth().currentUser.uid === guest.authID) {
          setGuestStatus(guest, 'verified', dispatch)
        }
      }  
    })
    .catch(function(error) {
      console.error("readBook", error);
      errorLog('guest-read-book', {error:error.code, guest:guest})
      dispatch({type: CLEAR_BOOK})
    }
  )
}


export const writeBook = (state, dispatch) => {
  let guest = state.guest
  let book = state.book
  var db = firebase.firestore()
  console.log('writeBook', guest.hostID, guest.bookID)
  let dbBook = {
    firstname: book.firstname,
    lastname: book.lastname,
    age: book.age,
    ocupation: book.ocupation,
    nationality: book.nationality,
    idPhoto: book.idPhoto,
    facePhoto: book.facePhoto,
    status: book.status,
  }
  if (book.allMemberValid) {
    dbBook.status = 'applied'
  }
  var batch = db.batch()
  var bookRef = db.collection('hosts').doc(guest.hostID)
                  .collection('books').doc(guest.bookID)
  var guestRef = db.collection("guests").doc(guest.id)
  batch.set(bookRef, dbBook, { merge: true })
  batch.set(guestRef, {status: dbBook.status}, { merge: true })
  batch.commit().then(function() {
    console.log('####book, guest書き込み成功')
    setGuestStatus(guest, dbBook.status, dispatch)
    writeLog(guest, 'write-guestbook', dbBook)
  })
  .catch(function(error) {
    console.error('####book, guest書き込み失敗', error)
    errorLog('guest-write-book', {error:error.code, book:book, data:dbBook})
  })
}




const prepareGuestMember = (book) => {
  //まずDB上には存在しない要素を作成して
  book.firstnameState = []
  book.lastnameState = []
  book.ageState = []
  book.ocupationState = []
  book.nationalityState = []
  book.memberDataValid = []
  book.idUrl = []
  book.faceUrl = []
  for (var i=0; i<book.numOfGuest; i++) {
    book.idUrl.push(defaultIdPhoto)
    book.faceUrl.push(defaultFacePhoto)
  }

  //DBに書き込むデータが存在しない場合はそれを作成
  if (!book.firstname) {
    book.firstname = []
    book.lastname = []
    book.age = []
    book.ocupation = []
    book.nationality = []
    book.idPhoto = []
    book.facePhoto = []
    for (var j=0; j<book.numOfGuest; j++) {
      book.firstname.push('')
      book.lastname.push('')
      book.age.push('')
      book.ocupation.push('')
      book.nationality.push('')
      book.idPhoto.push(false)
      book.facePhoto.push(false)
    }
    book.allMemberValid = false
  }
  setValidationState(book)
}


export const writeLog = (guest, event, content) => {
  //console.log('writeLog', event, content)
  let db = firebase.firestore()
  var log = {
    timestamp: firebase.firestore.Timestamp.now(),
    event: event,
    content: content
  }
  db.collection('hosts').doc(guest.hostID)
    .collection('books').doc(guest.bookID)
    .collection('logs').add(log)
  .then(function() {
  })
  .catch(function(error) {
    console.error('####book, log書き込み失敗', error)
  })
}


const setValidationState = (book) => {
  book.allMemberValid = true;
  for (var i=0; i<book.numOfGuest; i++) {
    book.memberDataValid[i] = true;
    if (verifyLength(book.firstname[i], 1)) {
      book.firstnameState[i] = 'success'
    }
    else {
      book.firstnameState[i] = 'error'
      book.memberDataValid[i] = false
      book.allMemberValid = false;
    }

    if (verifyLength(book.lastname[i], 1)) {
      book.lastnameState[i] = 'success'
    }
    else {
      book.lastnameState[i] = 'error'
      book.memberDataValid[i] = false
      book.allMemberValid = false;
    }
    
    if (verifyAge(book.age[i])) {
      book.ageState[i] = 'success'
    }
    else {
      book.ageState[i] = 'error'
      book.memberDataValid[i] = false
      book.allMemberValid = false;
    }

    if (verifyLength(book.ocupation[i], 1)) {
      book.ocupationState[i] = 'success'
    }
    else {
      book.ocupationState[i] = 'error'
      book.memberDataValid[i] = false
      book.allMemberValid = false;
    }

    if (verifyLength(book.nationality[i], 1)) {
      book.nationalityState[i] = 'success'
    }
    else {
      book.nationalityState[i] = 'error'
      book.memberDataValid[i] = false
      book.allMemberValid = false;
    }

    if (book.idPhoto[i] === false ||
      book.facePhoto[i] === false ) {
        book.allMemberValid = false;
    }
  }
}

const setPhotoUrl = (guest, book, dispatch) => {
  for (var i=0; i<book.numOfGuest; i++) {
    if(book.idPhoto[i]) {
      setImagePath(guest, i, 'id', dispatch)
    }
    if(book.facePhoto[i]) {
      setImagePath(guest, i, 'face', dispatch)
    }
  }
}