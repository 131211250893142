import React, { useContext } from 'react'
import AppContext from './contexts/AppContext'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { setLanguage } from './actions/locale'


export default function Header() {
  const { state, dispatch }  = useContext(AppContext)

  const onLangButton = (lang) => {
    dispatch(setLanguage(lang, state))
  }

  return (
    <Grid container justify='space-around'>
      <Grid item>
          これから民泊
      </Grid>
      <Grid item>
        <Grid container>
          <ButtonGroup size='small'>
            <Button onClick={()=>onLangButton('en-US')}>English</Button>
            <Button onClick={()=>onLangButton('zh-CN')}>中文</Button>
            <Button onClick={()=>onLangButton('ko-KR')}>한글</Button>
            <Button onClick={()=>onLangButton('ja-JP')}>日本語</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  )
}