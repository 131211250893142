import firebase from '../firebase'
import errorLog from '../errorLog'
import { SET_BOOK_VALUE } from 'actions'


export const setPhoto = (dispatch, state, index, type, file) => {
  //console.log('setFacePhoto', state, index, file)
  let reader = new FileReader()
  reader.onloadend = () => {
    let action = {
      type: SET_BOOK_VALUE,
      name: type + 'Url',
      index: index, 
      value: reader.result
    }
    dispatch(action)  //まず読み込んだ画像を表示しておいて
    //firebase strageに上げる
    let imagePath = '/hosts/' + state.guest.hostID + '/guests/' + state.guest.authID
                    + '/' + state.guest.bookID + '_' + index.toString() + '_' + type
    let ref = firebase.storage().ref().child(imagePath)
    let uploadTask = ref.put(file)
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      function(snapshot) {

      }, 
      function(error) {
        console.error('handleImageChange-put-to-strage', imagePath, error)
        console.log(firebase.auth().currentUser.uid)
        errorLog('guest-pur-image', {error:error.code, path:imagePath, guest:state.guest})
      },
      function() {
        //strageへの書き込みが完了
        ref.getDownloadURL().then(function(url) {
          //画像の読み込みURLをdispatchする
          action.value = url
          dispatch(action)
          //フラグをセット
          action.name = type + 'Photo'
          action.value = true
          dispatch(action)
        }).catch(function(error) {
          console.error('getDownloadURL', error)
        })
      }
    )
  }
  reader.readAsDataURL(file)
}


export const setImagePath = (guest, index, type, dispatch) => {
  let imagePath = '/hosts/' + guest.hostID + '/guests/' + guest.authID
    + '/' + guest.bookID + '_' + index.toString() + '_' + type
  let ref = firebase.storage().ref().child(imagePath)
  ref.getDownloadURL().then(function(url) {
    let action = {
      type: SET_BOOK_VALUE,
      name: type + 'Url',
      index: index,
      value: url,
    }
    dispatch(action)
  }).catch(function(error) {
    console.error('getDownloadURL', error)
  })
}
