import firebase from '../firebase'
import { readBook } from './book'
import { hist } from 'App';
import { SET_GUEST, CLEAR_GUEST, } from '../actions'
import errorLog from '../errorLog'


export const readGuest = (guestid, dispatch) => {
  console.log('readGuest', guestid)
  let authUser = firebase.auth().currentUser
  if (guestid.length === 8) {
    //指定idのguestをコレクションから読み込む
    firebase.firestore().collection("guests").doc(guestid).get()
    .then(
      function(doc) {
        if (!(doc.exists)) {
          console.log('####guestIDが存在しない:', guestid)
          dispatch({type: CLEAR_GUEST})
          return
        } else {
          console.log('####got_guest')
          let guest = doc.data()
          guest.id = guestid
          prepareGuest(guest, dispatch)
        }
      }
    )
    .catch(function(error) {
      console.error("####db読み込み失敗 ", error);
      errorLog('guest-read-guest', {guestid: guestid, auth: authUser})
      console.log('auth=', authUser)
      hist.push('/login?'+guestid)
      dispatch({type: CLEAR_GUEST})
    })
  } else {
    //有効なguestidが与えられていない場合
    if (authUser) {
      firebase.firestore().collection("guests")
      .where('authID', '==', authUser.uid).get()
      .then(function(querySnapshot) {
        if (querySnapshot.size) {
          console.log('####guestクエリ成功', authUser.uid, querySnapshot.size)
          let guest = querySnapshot.docs[0].data()
          guest.id = querySnapshot.docs[0].id
          prepareGuest(guest, dispatch)
        } else {
          console.log('現在ログインしているauthUserに対応するguestは存在しない', authUser.uid)
          errorLog('guest-no-guest-for-auth-user', authUser)
        }
      })
      .catch(function(error) {
        console.log('authIDに対するクエリが失敗')
        errorLog('guest-query-for-auth-user', {error:error.code, user:authUser})
      })
    } else {
      hist.push('/login')
    }
  }
}


//DBからguestを読んだ後Stepperの状態をセットして、Login状態をチェックする
export const prepareGuest = (guest, dispatch) => {
  //console.log('prepareGuest', guest)
  prepareStepperState(guest)
  let authUser = firebase.auth().currentUser
  if (guest.status === 'initial') {
    if (authUser) { //まだAuthのuser作られてないはず
      firebase.auth().signOut()
    }
  } else {
    if (authUser) {
      console.log('ログイン中', authUser.uid)
      if (guest.authID === authUser.uid) {
        if (authUser.emailVerified) { //メアドの確認が済んでたら
          readBook(guest, dispatch)   //DBからbookを読む
        }
      } else {
        console.log('IDが一致しない')
        writeLog(guest, 'guestid-differnt', authUser.uid)
        hist.push('/login')
      }
    } else {
      console.log('ログインしていない')
      hist.push('/login')
    }  
  }
  dispatch({type: SET_GUEST, guest})
}


//bookstatusの更新
export const setGuestStatus = (guest, status, dispatch) => {
  console.log('setGuestStatus', guest.id, status)
  //まずDB内のstatusを更新して
  var newStatus = { status: status }
  var mergeOption = { merge: true }
  var db = firebase.firestore()
  var batch = db.batch()
  var bookRef = db.collection('hosts').doc(guest.hostID)
                  .collection('books').doc(guest.bookID)
  var guestRef = db.collection("guests").doc(guest.id)
  batch.set(bookRef, newStatus, mergeOption)
  batch.set(guestRef, newStatus, mergeOption)
  batch.commit().then(function() {
    console.log('####status書き込み成功')
    writeLog(guest, 'guest-new-status', newStatus)
    let newGuest = {...guest, status: status}
    prepareStepperState(newGuest)
    dispatch({ type: SET_GUEST, guest: newGuest })
  })
  .catch(function(error) {
    console.log('####status書き込み失敗', error)
    errorLog('host-write-book', {error:error.code, newStatus})
  })
}


const prepareStepperState = (guest) => {
  switch (guest.status) {
    case 'initial':
    case 'registerd':
      guest.activeStep = 0
      break
    case 'verified':
      guest.activeStep = 1
      break
    case 'agreed':
    case 'applied':
      guest.activeStep = 2
      break
    case 'approved':
      guest.activeStep = 3
      break
    default:
  }
  guest.completedStep = guest.activeStep - 1
}


export const writeLog = (guest, event, content) => {
  //console.log('writeLog', guest, event, content)
  //console.log(firebase.auth().currentUser.uid)
  let db = firebase.firestore()
  var log = {
    timestamp: firebase.firestore.Timestamp.now(),
    event: event,
    content: content
  }
  db.collection('guests').doc(guest.id).collection('logs').add(log)
  .then(function() {
  })
  .catch(function(error) {
    console.error('####guestlog書き込み失敗', error)
  })
}