const ja_JP = {
  //About page
  'about.title': 'クラウドチェックインについて',
  'about.description': '事前に宿泊者名簿を記入いただけるシステムです',

  //footer menu
  'footer.faq': 'よくある質問',
  'footer.feedback': 'お問い合わせ',
  'footer.terms': '利用規約',
  'footer.privacy': '個人情報保護方針',

  //登録ページ
  'resgit.title': 'サイトへの登録',
  'regist.msg': '{guest}様、{property}へのご予約ありがとうございます。 現在日本では法令により、宿泊者様の情報を記録することが求められています。 {property}では、当サイト（CloudCheckin）を利用することで、宿泊者様に空き時間を利用して、宿泊者名簿にご記入いただけるようお願いしています。 宿泊者様の個人情報保護のために、メールアドレスとパスワードで当サイトへの登録していただき、その後宿泊者名簿の記入をお願いします。',
  'regist.sentmsg': '{guest}様、登録のメールアドレス({email})に確認のメールを送りました。メールを確認の上リンクをクリックしてください',
  'regist.donemsg': '{guest}様、登録ありがとうございます',
  'regist.email': 'メールアドレス',
  'regist.password': 'パスワード',
  'regist.passwordconf': 'パスワードの確認',
  'regist.button': '登録',
  'regist.resendbutton': '確認メールの再送信',
  'regist.okbutton': '次へ',

  //約款ページ
  'agreement.title': '宿泊約款の確認',
  'agreement.description': '以下の約款をお読みいただき確認ボタンを押してください',
  'agreement.done': '約款の確認ありがとうございました',
  'agreement.button': '確認しました',
  'agreement.buttonagreed': '次へ',

  //宿泊者名簿ページ
  'guestbook.title': '宿泊者名簿の記入',
  'guestbook.request': 'ご利用いただく全てのお客様につきまして、各々以下の情報の入力をお願いします。',
  'guestbook.itemsinvaid': '全ての項目に記入をお願いします。',
  'guestbook.photoinvalid': '写真入りの身分証明書類と、御自身の顔写真の登録をお願いします',
  'guestbook.thanks': '名簿へのご記入ありがとうございます、ホストによる確認が完了するまで今しばらくお待ちください',
  'guestbook.aproved': '名簿へのご記入ありがとうございました。物件情報ページで入室方法などをご覧ください',
  'guestbook.firstname': '姓',
  'guestbook.lastname': '名',
  'guestbook.age': '年齢',
  'guestbook.ocupation': '職業',
  'guestbook.nationality': '国籍',
  'guestbook.idphoto': 'パスポート写真',
  'guestbook.facephoto': '顔写真',
  'guestbook.selectimage': '画像の選択',
  'guestbook.change': '画像の変更',
  'guestbook.apply': '登録',
  'guestbook.modify': '訂正',
  'guestbook.next': '次へ',
  'guestbook.dlgOk': '確認',

  //物件情報ページ
  'propertyinfo.title': '物件情報',
  'propertyinfo.description': 'ここで解錠方法を含む物件の情報を表示する',

  //FAQページ
  'faq.title': 'よくある質問（FAQ)',
  'faq.description': '弊社に寄せられたよくある質問と回答を紹介します',

  //問合せページ
  'feedback.title': 'お問い合わせ',
  'feedback.description': 'わからないことがございましたら、お気軽にお問い合わせください',

  //利用規約ページ
  'terms.title': '利用規約',
  'terms.description': 'ここに利用規約を記載します',

  //個人情報保護規定ページ
  'privacy.title': '個人情報保護規定',
  'privacy.description': 'ここにプライバシーポリシーを記載します',
}


export default ja_JP