import { SET_LOCALE,} from '../actions'

const locale = (state = {}, action) => {
  switch(action.type) {
    case SET_LOCALE:
      return {locale: action.locale, messages: action.messages}
    default:
      return state
  }
}

export default locale