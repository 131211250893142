import {
  SET_BOOK_VALUE,
} from '../actions'
import { verifyEmail, verifyAge, verifyLength } from './verifyfuncs'

export const changeGuestMemberProperty = (event, dispatch, index, stateName, type, stateNameEqualTo) => {
  switch (type) {
    case "email":
      if (verifyEmail(event.target.value)) {
        setState(dispatch, index, stateName + "State", "success")
      } else {
        setState(dispatch, index, stateName + "State", "error")
      }
      break;
    case "age":
      if (verifyAge(event.target.value)) {
        setState(dispatch, index, [stateName + "State"], "success")
      } else {
        setState(dispatch, index, [stateName + "State"], "error")
      }
      break;
    case "length":
    default:
      if (verifyLength(event.target.value, stateNameEqualTo)) {
        setState(dispatch, index, [stateName + "State"], "success")
      } else {
        setState(dispatch, index, [stateName + "State"], "error")
      }
      break;
  }
  setState(dispatch, index, stateName, event.target.value);
  //this.props.callback(this.props.index, this.isValidated(), { [stateName]: event.target.value });
}

const setState = (dispatch, index, name, value) => {
  dispatch({
    type: SET_BOOK_VALUE,
    index: index,
    name: name,
    value: value
  })
}
