import firebase from '../firebase'
import { SET_GUEST_STATUS } from '../actions'
import { writeLog } from '../actions/guest'
import errorLog from '../errorLog'
import { hist } from 'App';

export const registGuest = (guest, dispatch, registerEmail, registerPassword) => {
  console.log("registGuest", guest, registerEmail, registerPassword)  

  firebase.auth().createUserWithEmailAndPassword(registerEmail, registerPassword)
  .then(function(result) {
    var user = firebase.auth().currentUser
    console.log('アカウント作成成功',{user})
    if (!user) {
      console.log('####auth account作成成功したのにuserがNULL')
      errorLog('guest-create-no-login-user', guest)
      return
    }
    writeAuthID(guest, dispatch, registerEmail)

  }).catch(function(error) {
    if (error.code !== 'auth/email-already-in-use') {
      console.error('アカウント作成失敗', error)
      errorLog('guest-create-account', {error:error.code, guest:guest})  
      hist.push('/faq?'+guest.id)
    }
    firebase.auth().signInWithEmailAndPassword(registerEmail, registerPassword)
      .then(function(result) {
        console.log('既に登録されてたemailでログイン成功')
        writeAuthID(guest, dispatch, registerEmail)
      }).catch(function(error) {
        errorLog('guest-register-used', error.code)
        alert('このメールアドレスは既に登録されています')
        console.error('サインイン失敗: ', {error})
        hist.push('/login?'+guest.id)
    });	
  })
}

export const writeAuthID = (guest, dispatch, registerEmail) => {
  var user = firebase.auth().currentUser
  var status
  if (user.emailValidated)
    status = 'verified'
  else
    status = 'registerd'
  console.log('writeAuthID',{guest},{user})
  var guestDoc = {
    authID: user.uid,
    email: registerEmail,
    status: status
  }
  var bookDoc = {
    guestAuthID: user.uid,
    email: registerEmail,
    status: status
  }
  let temp = {...guest, ...guestDoc}
  var mergeOption = { merge: true }
  var db = firebase.firestore()
  var batch = db.batch()
  var guestRef = db.collection('guests').doc(guest.id)
  var bookRef = db.collection('hosts').doc(guest.hostID)
                  .collection('books').doc(guest.bookID)
  batch.set(guestRef, guestDoc, mergeOption)
  batch.set(bookRef, bookDoc, mergeOption)
  batch.commit().then(function() {
    console.log('####registGuest-succes', temp)
    writeLog(temp, 'guest-create-user', temp)
    if (!user.emailValidated)
      sendVerifyMail(guest) //メールアドレス認証のメールを送信する
    dispatch({ type: SET_GUEST_STATUS, status: status })
  })
  .catch(function(error) {
    console.error("Error adding document: ", error)
    errorLog('guest-add-to-guest/host', temp)
  })
}


export const sendVerifyMail = (guest) => {
  var actionCodeSettings = {
    url: 'https://guest.i-sis.net/register?' + guest.id,
    //url: 'http://localhost:3000/register?' + guest.id,
  };
  firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
    .then(function() {
      console.log('Verification email sent.')
    })
    .catch(function(error) {
      console.error('Error occurred.', error)
      errorLog('guest-send-veryfy-mail', {error:error.code, guest:guest})
    });  
}