import firebase from './firebase'

const errorLog = (event, content) => {
  //console.log('errorLog', event, content)
  let db = firebase.firestore()
  var log = {
    timestamp: firebase.firestore.Timestamp.now(),
    event: event,
    content: content
  }
  db.collection('errorLogs').add(log)
  .then(function() {
  })
  .catch(function(error) {
    console.error('####errorLog書き込み失敗', error)
  })
}

export default errorLog