import React, { useContext, useEffect } from 'react';
import firebase from '../firebase';

import { makeStyles } from '@material-ui/core/styles';
import AppContext from '../contexts/AppContext'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Register from "./Register.js";
import Agreement from './Agreement.js';
import Guestbook from './GuestBook.js';
import PropertyInfo from './PropertyInfo'
import { readGuest } from '../actions/guest.js'
import { FormattedMessage } from 'react-intl'
import { hist } from 'App'
import { SET_ACTIVE_STEP } from '../actions'


const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const GuestStepper = () => {
  const { state, dispatch }  = useContext(AppContext)
  const classes = useStyles()

  useEffect(
    () => {
      //URLからguestidの取り出し
      var guestid = (window.location.search) ?	window.location.search.substring(1,window.location.search.length) : ""
      console.log('GuestStepper.useEffect', guestid)
      if (guestid.length === 8) {
        readGuest(guestid, dispatch)
      } else {
        hist.push('/about')
      }
    }, [dispatch]
  )

  const handleStep = step => () => {
    if (state.guest.completedStep+1 >= step) {
    //現在のstatsuに応じて遷移可能なstepを制限するよりはstepをdisableにしたい
      dispatch({
        type: SET_ACTIVE_STEP,
        step: step
      })
    }
  }

  function isStepComplete(step) {
    return step <= state.guest.completedStep && step !== state.guest.activeStep
  }

  const handleState = () => {
    firebase.auth().signOut();
  }

  const stepContent = () => {
    switch (state.guest.activeStep) {
    case 0:
      return  (<Register />);
    case 1:
        return  (<Agreement />);
    case 2:
        return  (<Guestbook />);
    case 3:
        return  (<PropertyInfo />);
    default:
      return null
    }
  }

  const steps = [
    <FormattedMessage id='resgit.title'/>,
    <FormattedMessage id='agreement.title'/>,
    <FormattedMessage id='guestbook.title'/>,
    <FormattedMessage id='propertyinfo.title'/>,
  ]
  
  return (
    <div className={classes.root}>
      <Stepper alternativeLabel nonLinear activeStep={state.guest.activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

      <Button variant="outlined" className={classes.margin}
        onClick={handleState} >
        {state.guest.status}
      </Button>

      {stepContent()}

    </div>
  );
}

export default GuestStepper

/*
<div>
<Register activeStep={activeStep} />
<Agreement activeStep={activeStep} />
<Guestbook activeStep={activeStep} />
<PropertyInfo activeStep={activeStep} />
</div>
*/