import {
  SET_BOOK,
  SET_BOOK_VALUE,
  CLEAR_BOOK,
} from '../actions'

const initialState = {
  numOfGuest: 1,
  allMemberValid: false,
  memberDataValid: [false],
  firstname: [],
  firstnameState: [],
  lastname: [],
  lastnameState: [],
  age: [],
  ageState: [],
  ocupation: [],
  ocupationState: [],
  nationality: [],
  nationalityState: [],
}

const book = (state = initialState, action) => {
  switch(action.type) {
    case SET_BOOK:
      return action.book
    case SET_BOOK_VALUE:
      let newState=JSON.parse(JSON.stringify(state))
      newState[action.name][action.index]=action.value
      setMemberDataValid(newState)
      return newState
    case CLEAR_BOOK:
      return initialState
    default:
      return state
  }
}
export default book



const setMemberDataValid = (state) => {
  state.allMemberValid = true;
  for (var i=0; i<state.numOfGuest; i++) {
    state.memberDataValid[i] = true;
    if (state.lastnameState[i] === 'error') {
      state.memberDataValid[i] = false
      state.allMemberValid = false;
      break
    }
    if (state.ageState[i] === 'error') {
      state.memberDataValid[i] = false
      state.allMemberValid = false;
      break
    }
    if (state.ocupationState[i] === 'error') {
      state.memberDataValid[i] = false
      state.allMemberValid = false;
      break
    }
    if (state.nationalityState[i] === 'error') {
      state.memberDataValid[i] = false
      state.allMemberValid = false;
      break
    }
    if (state.idPhoto[i] === false ||
      state.facePhoto[i] === false ) {
        state.allMemberValid = false;
    }
  }
}