import { SET_LOCALE } from 'actions'

import en_US from 'locales/en_US'
import ja_JP from 'locales/ja_JP'
import zh_CN from 'locales/zh_CN'
import ko_KR from 'locales/ko_KR'


export const setLanguage = (lang, state) => {
  console.log('setLocale', lang)

  let action = {
    type: SET_LOCALE,
  }
  switch (lang) {
    case 'ja':
    case 'ja-JP':
      action.locale = 'ja'
      action.messages = ja_JP
      break

    case 'zh':
    case 'zh-CN':
      action.locale = 'zh'
      action.messages = zh_CN
      break

    case 'ko':
    case 'ko-KR':
      action.locale = 'ko'
      action.messages = ko_KR
      break
      
    case 'en':
    default:
      action.locale = 'en'
      action.messages = en_US
      break
    }

    if (state) {
      //writeToDB(locale, state)
    }
    return  action
}