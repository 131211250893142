//中国語 - 簡体字
const zh_CN = {
  //About page
  'about.title': '关于云端值机',
  'about.description': '这是一个可以预先填写来宾列表的系统',
  
  //footer menu
  'footer.faq': '常见问题',
  'footer.feedback': '联络我们',
  'footer.terms': '使用条款',
  'footer.privacy': '私隐政策',

  //登録ページ
  'resgit.title': 'Register on this site',
  'regist.msg': 'Dear Mr./Ms. {guest}, Thank you for reservation to "{property}" due to official reguration',
  'regist.sentmsg': 'We sent to you({email}) to confirm your mail address. Please check the email, and click the link in the mail.',
  'regist.donemsg': 'Dear Ms/Ms {guest}, Thank you for registration',
  'regist.email': 'e-mail',
  'regist.password': 'password',
  'regist.passwordconf': 'password comfirmation',
  'regist.button': 'Regiser',
  'regist.resendbutton': 'Re-send address cofirmation mail',
  'regist.okbutton': 'next',

  //約款ページ
  'agreement.title': 'Provision for accomodation',
  'agreement.description': 'Please read the following terms and conditions and press the confirmation button',
  'agreement.done': 'Thank you for the approval of the Terms and Conditions',
  'agreement.button': 'Agree',
  'agreement.buttonagreed': 'Next',

  //宿泊者名簿ページ
  'guestbook.title': 'Guest Book',
  'guestbook.request': 'Please enter the following information for all guests.',
  'guestbook.itemsinvaid': 'please write all items.',
  'guestbook.photoinvalid': 'Please register your photo ID and face photo',
  'guestbook.thanks': 'Thank you for filling out the guest book, please wait for a while for the host to verify',
  'guestbook.aproved': 'Thank you for filling in the list. Please see how to enter the room on the property information page.',
  'guestbook.firstname': 'last name',
  'guestbook.lastname': 'first name',
  'guestbook.age': 'age',
  'guestbook.ocupation': 'occupation',
  'guestbook.nationality': 'nationality',
  'guestbook.idphoto': 'ID photo',
  'guestbook.facephoto': 'face photo',
  'guestbook.selectimage': 'select photo',
  'guestbook.change': 'change photo',
  'guestbook.apply': 'apply',
  'guestbook.modify': 'change',
  'guestbook.next': 'next',
  'guestbook.dlgOk': 'ok',

  //物件情報ページ
  'propertyinfo.title': 'Property info',
  'propertyinfo.description': 'Display property information including unlocking method here',

  //FAQページ
  'faq.title': 'FAQ',
  'faq.description': 'Frequently Asked Questions',

  //問合せページ
  'feedback.title': 'feedback',
  'feedback.description': 'If you have any questions, please feel free to contact us',

  //利用規約ページ
  'terms.title': 'terms of use',
  'terms.description': 'Here are the terms of service',

  //個人情報保護規定ページ
  'privacy.title': 'privacy poricy',
  'privacy.description': 'Here is the privacy policy',
}


export default zh_CN