export const SET_GUEST = 'SET_GUEST'
export const SET_GUEST_STATUS = 'SET_GUEST_STATUS'
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP'
export const CLEAR_GUEST = 'CLEAR_GUEST'

export const SET_BOOK = 'SET_BOOK'
export const SET_BOOK_VALUE = 'SET_BOOK_VALUE'
export const CLEAR_BOOK = 'CLEAR_BOOK'

export const SET_LOCALE = 'SET_LOCALE'

export const ADD_OPERATION_LOG = 'ADD_OPERATION_LOG'
export const DELETE_ALL_OPERATION_LOGS = 'DELETE_ALL_OPERATION_LOGS'

const timeCurrentIso8601 = () => (new Date()).toISOString()

export const addOperationLog = (description) => ({
  type: ADD_OPERATION_LOG,
  description: description,
  operatedAt: timeCurrentIso8601()
})