/*eslint-disable*/
import React, { useContext } from 'react'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// material ui icons
import Contacts from "@material-ui/icons/Contacts";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import AppContext from './contexts/AppContext'
import { login } from './actions/login'
import { verifyEmail, verifyLength } from 'actions/verifyfuncs'

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const { state, dispatch }  = useContext(AppContext)
  // login form
  const [loginEmail, setloginEmail] = React.useState("");
  const [loginEmailState, setloginEmailState] = React.useState("");
  const [loginPassword, setloginPassword] = React.useState("");
  const [loginPasswordState, setloginPasswordState] = React.useState("");

  var guestid = (window.location.search) ?	window.location.search.substring(1,window.location.search.length) : ""
  console.log('LoginPage', guestid)

  const loginClick = () => {
    if (loginEmailState === "error") { return; }
    if (loginPasswordState === "error") { return; }
    login(loginEmail, loginPassword, state.guest, dispatch)
  };
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Contacts />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Login Form</h4>
            </CardHeader>
            <CardBody>
            <form>
              <CustomInput
                success={loginEmailState === "success"}
                error={loginEmailState === "error"}
                labelText="Email Address *"
                id="loginemail"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => {
                    if (verifyEmail(event.target.value)) {
                      setloginEmailState("success");
                    } else {
                      setloginEmailState("error");
                    }
                    setloginEmail(event.target.value);
                  },
                  type: "email"
                }}
              />
              <CustomInput
                success={loginPasswordState === "success"}
                error={loginPasswordState === "error"}
                labelText="Password *"
                id="loginpassword"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => {
                    if (verifyLength(event.target.value, 6)) {
                      setloginPasswordState("success");
                    } else {
                      setloginPasswordState("error");
                    }
                    setloginPassword(event.target.value);
                  },
                  type: "password",
                  autoComplete: "off"
                }}
              />
              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>
              <div className={classes.center}>
                <Button color="rose" onClick={loginClick}>
                  Login
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    </div>
  );
}
