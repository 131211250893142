import React, { useContext, useState } from 'react';
import AppContext from '../contexts/AppContext'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js"

import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Close from "@material-ui/icons/Close";

import GuestMember from 'stepper/GuestMember'
import { writeBook } from '../actions/book'
import { FormattedMessage } from 'react-intl'
import { SET_ACTIVE_STEP } from '../actions'


const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  marginTop30: {
    marginTop: "30px"
  },
  root: {
    flexGrow: 1,
    width: '100%',
    //ßbackgroundColor: theme.palette.background.paper,
  },
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(styles);

export default function GuestBook() {
  const { state, dispatch }  = useContext(AppContext)
  const [tabValue, setTabValue] = useState(0)
  const [dlgOpen, setDlgOpen] = useState(false)
  const [dlgMsg, setDlgMsg] = useState('guestbook.itemsinvaid')
  const classes = useStyles()
  let book=state.book


  //タブの変更
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  //「次へ」ボタン
  const handleNext = () => {
    console.log('handleNext', state)
    if (guest.status === 'applied') {
      //承認前は書き換えを許す
      writeBook(state, dispatch)
      return
    }
    if (guest.status === 'approved') {
      //承認済みなら物件情報に進める
      dispatch({type: SET_ACTIVE_STEP, step: 3})
      return
    }

    //入力項目を確認して必要ならwarninngを出す
    if (book.memberDataValid[tabValue] === false) {
      setDlgMsg('guestbook.itemsinvaid')
      setDlgOpen(true)
      return
    }
    if (book.idPhoto[tabValue] === false ||
      book.facePhoto[tabValue] === false) {
      setDlgMsg('guestbook.photoinvalid')
      setDlgOpen(true)
      return
    }

    writeBook(state, dispatch)
    if (book.allMemberValid) {
      setDlgMsg('guestbook.thanks')
      setDlgOpen(true)
      return
    }

    if (tabValue >= book.numOfGuest)
      setTabValue(0)
    else
      setTabValue(tabValue+1)
  }


  let tabLabel = []
  if (book.numOfGuest) {
    tabLabel.push('代表者')
    for (var i=1; i<book.numOfGuest; i++)
      tabLabel.push('同行者'+String(i))
  }

  let guest = state.guest
  var descriptionText, buttonText
  switch (guest.status) {
    case 'applied':
      descriptionText = 'guestbook.thanks'
      buttonText = 'guestbook.modify'
      break
    case 'approved':
      descriptionText = 'guestbook.aproved'
      buttonText = 'guestbook.next'
      break
    default:
      descriptionText = 'guestbook.request'
      buttonText = 'guestbook.next'
      break
  }


  return (
    <GridContainer justify="center">
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" text>
            <CardText color="rose">
              <h4 className={classes.cardTitleWhite}>
                <FormattedMessage id='guestbook.title' />
              </h4>
              <h5 className={classes.cardCategoryWhite}>
                <FormattedMessage id={descriptionText} />
              </h5>
            </CardText>
          </CardHeader>
          <CardBody>
            <AppBar position="static" color="default">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {tabLabel.map((label, index) => {
                  return (
                    <Tab key={label} label={label} {...a11yProps(index)} />
                  )
                })}
              </Tabs>
            </AppBar>
            {tabLabel.map((label, index) => {
              return (
                <GuestMember  key={label} value={tabValue} index={index} />
              )
            })}
            <Button color="rose" onClick={handleNext}>
              <FormattedMessage id={buttonText} />
            </Button>
          </CardBody>
        </Card>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall
          }}
          open={dlgOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setDlgOpen(false)}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setDlgOpen(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          </DialogTitle>
            <DialogContent
              id="small-modal-slide-description"
              className={
                classes.modalBody + " " + classes.modalSmallBody
              }
            >
              <h5><FormattedMessage id={dlgMsg}/></h5>
            </DialogContent>
            <DialogActions
              className={
                classes.modalFooter + " " + classes.modalFooterCenter
              }
            >
            <Button
              onClick={() => setDlgOpen(false)}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              <FormattedMessage id='guestbook.dlgOk'/>
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  )
}