import {
  SET_GUEST,
  SET_GUEST_STATUS,
  SET_ACTIVE_STEP,
  CLEAR_GUEST,
} from '../actions'

const initialState = {
  id: '',
  status: 'initial'
}
const guest = (state = initialState, action) => {
  switch(action.type) {
    case SET_GUEST:
      return action.guest
    case SET_GUEST_STATUS:
      return {...state, status:action.status}
    case SET_ACTIVE_STEP:
      return  {...state, activeStep: action.step}
    case CLEAR_GUEST:
      return  initialState
    default:
      return state
  }
}

export default guest