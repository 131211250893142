import React, { useReducer, useEffect } from "react";
import reducer from './reducers'
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider,} from 'react-intl'
import en_US from 'locales/en_US'

import AppContext from './contexts/AppContext'
import Stepper from "./stepper/Stepper.js";
import Login from "Login.js";
import Faq from "Faq.js";
import Feedback from "Feedback.js";
import Terms from "Terms.js";
import Privacy from "Privacy.js";
import About from "About.js";
import { setLanguage } from './actions/locale'

import Header from 'Header'
import Footer from 'Footer'
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

export const hist = createBrowserHistory();

export const App = () => {
  const initialState = {
    guest: {
      id: '',
      status: 'initial',
      activeStep: 0
    },
    book: {
      guestID: '',
    },
    locale: {
      locale: 'en',
      messages: en_US
    },
    operationLogs: []
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(
    () => {
      dispatch(setLanguage(getBrowserLanguage(), false))
    }, [dispatch]
  )


  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <IntlProvider locale={state.locale.locale} messages={state.locale.messages} >
        <Header />
        <Router history={hist}>
          <Switch>
            <Route path="/register" component={Stepper} />
            <Route path="/login" component={Login} />
            <Route path="/faq" component={Faq} />
            <Route path="/feedback" component={Feedback} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/about" component={About} />
            <Redirect from="/" to="/register" />
          </Switch>
        </Router>
        <Footer />
      </IntlProvider>
    </AppContext.Provider>
  )
}

const getBrowserLanguage = () => {
  if (window.navigator.languages) {
    return window.navigator.languages[0]
  }

  if (window.navigator.language) {
    return  window.navigator.language
  }

  if (window.navigator.userLanguage) {
    return  window.navigator.userLanguage
  }

  if (window.navigator.browserLanguage) {
    return  window.navigator.browserLanguage
  }
  return 'en'
}