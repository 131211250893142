import React, { useContext,} from 'react'
import AppContext from '../contexts/AppContext'
// used for making the prop types of this component
import PropTypes from "prop-types"

// core components
import Button from "components/CustomButtons/Button.js";

import { FormattedMessage } from 'react-intl'

import { setPhoto } from 'actions/photo'

export function ImageUploadId(props) {
  const { state, dispatch }  = useContext(AppContext)
  let fileInput = React.createRef();
  let { index, addButtonProps, changeButtonProps } = props

  const handleImageChange = e => {
    e.preventDefault();
    let file = e.target.files[0]
    setPhoto(dispatch, state, index, 'id', file)
  }

  const handleClick = () => {
    fileInput.current.click()
  }

  return (
    <div className="fileinput text-center">
      <input type="file" accept="image/png, image/jpeg"　
        onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail"}>
        <img src={state.book.idUrl[index]} alt="..." />
      </div>
      <div>
        {state.book.idPhoto[index] ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            <FormattedMessage id='guestbook.change'/>
          </Button>
        ) : (
          <Button {...changeButtonProps} onClick={() => handleClick()}>
            <FormattedMessage id='guestbook.selectimage'/>
          </Button>
      )}
      </div>
    </div>
  );
}


export function ImageUploadFace(props) {
  const { state, dispatch }  = useContext(AppContext)
  let fileInput = React.createRef();
  let { index, addButtonProps, changeButtonProps } = props

  const handleImageChange = e => {
    e.preventDefault();
    let file = e.target.files[0]
    setPhoto(dispatch, state, index, 'face', file)
  }

  const handleClick = () => {
    fileInput.current.click()
  }

  return (
    <div className="fileinput text-center">
      <input type="file" accept="image/png, image/jpeg"　
        onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail img-circle"}>
        <img src={state.book.faceUrl[index]} alt="..." />
      </div>
      <div>
        {state.book.facePhoto[index] ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            <FormattedMessage id='guestbook.change'/>
          </Button>
        ) : (
          <Button {...changeButtonProps} onClick={() => handleClick()}>
            <FormattedMessage id='guestbook.selectimage'/>
          </Button>
      )}
      </div>
    </div>
  );
}

ImageUploadId.propTypes = {
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
}

ImageUploadFace.propTypes = {
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
}