/*eslint-disable*/
import React, { useContext, useState } from 'react'

import AppContext from '../contexts/AppContext'

import { setGuestStatus } from '../actions/guest'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";

import { FormattedMessage } from 'react-intl'
import { SET_ACTIVE_STEP } from '../actions'

import {
  cardTitle,
  roseColor
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0"
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem"
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px"
  },
  marginTop30: {
    marginTop: "30px"
  },
};

const useStyles = makeStyles(styles);

export default function Agreement() {
  const { state, dispatch }  = useContext(AppContext)
  const classes = useStyles();

  const handleAgree = () => {
    if (state.guest.status === 'verified') {
      setGuestStatus(state.guest, 'agreed', dispatch)
    } else {
      dispatch({type: SET_ACTIVE_STEP, step: 2})
    }
  }

  let buttonTextId;
  if (state.guest.status === 'verified') {
    buttonTextId = 'agreement.button'
  }
  else {
    buttonTextId = 'agreement.buttonagreed'
  }

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitleWhite}>
                  <FormattedMessage id='agreement.title' />
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  <FormattedMessage id='agreement.description' />
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Button round color="primary" onClick={handleAgree}>
                <FormattedMessage id={buttonTextId} />
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
