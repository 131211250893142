import firebase from '../firebase'
import errorLog from '../errorLog'
import { writeAuthID } from './register'
import { hist } from 'App'


export const login = (email, password, guest, dispatch) => {
  console.log('login', guest)
  firebase.auth().signInWithEmailAndPassword(email, password)
  .then(function(result) {
    console.log('Sign-in successful.')
    if (guest.status === 'initial')
    {
      writeAuthID(guest, dispatch, email)
    }
    hist.push('/register?'+guest.id)
  }).catch(function(error) {
    var errorCode = error.code;
    var errorMessage = error.message;
    alert(errorMessage)
    console.error('サインイン失敗: ',errorCode, errorMessage)
    errorLog('guest-signin', error.code)
  });	
}

