import React, { useContext } from 'react';
import AppContext from '../contexts/AppContext'

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { ImageUploadId, ImageUploadFace } from "./ImageUpload.js";
import { FormattedMessage } from 'react-intl'
import { changeGuestMemberProperty } from '../actions/member' 


const GuestMember = (props) => {
  const { state, dispatch }  = useContext(AppContext)

  if (props.index !== props.value)
    return null;

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={4}>
        <CustomInput
          success={state.book.firstnameState[props.index] === "success"}
          error={state.book.firstnameState[props.index] === "error"}
          labelText={<span>{<FormattedMessage id='guestbook.firstname' />}</span>}
          id="firstname"
          formControlProps={{fullWidth: true}}
          inputProps={{
            onChange: event => changeGuestMemberProperty(event, dispatch, props.index, "firstname", "length", 1),
            defaultValue: state.book.firstname[props.index]
          }}
        />
        <CustomInput
          success={state.book.lastnameState[props.index] === "success"}
          error={state.book.lastnameState[props.index] === "error"}
          labelText={<span>{<FormattedMessage id='guestbook.lastname'/>}</span>}
          id="lastname"
          formControlProps={{fullWidth: true}}
          inputProps={{
            onChange: event => changeGuestMemberProperty(event, dispatch, props.index, "lastname", "length", 1),
            defaultValue: state.book.lastname[props.index]
          }}
        />
        <CustomInput
          success={state.book.ageState[props.index] === "success"}
          error={state.book.ageState[props.index] === "error"}
          labelText={<span>{<FormattedMessage id='guestbook.age'/>}</span>}
          id="age"
          formControlProps={{fullWidth: true}}
          inputProps={{
            onChange: event => changeGuestMemberProperty(event, dispatch, props.index, "age", "age"),
            defaultValue: state.book.age[props.index]
          }}
        />
        <CustomInput
          success={state.book.ocupationState[props.index] === "success"}
          error={state.book.ocupationState[props.index] === "error"}
          labelText={<span>{<FormattedMessage id='guestbook.ocupation'/>}</span> }
          id="ocupation"
          formControlProps={{fullWidth: true}}
          inputProps={{
            onChange: event => changeGuestMemberProperty(event, dispatch, props.index, "ocupation", "length", 2),
            defaultValue: state.book.ocupation[props.index]
          }}
        />
        <CustomInput
          success={state.book.nationalityState[props.index] === "success"}
          error={state.book.nationalityState[props.index] === "error"}
          labelText={<span>{<FormattedMessage id='guestbook.nationality'/>}</span> }
          id="nationality"
          formControlProps={{fullWidth: true}}
          inputProps={{
            onChange: event => changeGuestMemberProperty(event, dispatch, props.index, "nationality", "length", 2),
            defaultValue: state.book.nationality[props.index]
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={8}>
                <legend>{<FormattedMessage id='guestbook.idphoto'/>}</legend>
                <ImageUploadId
                  index = {props.index}
                  addButtonProps={{
                    color: "rose",
                    round: true
                  }}
                  changeButtonProps={{
                    color: "rose",
                    round: true
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: true
                  }}
                />
                <legend>{<FormattedMessage id='guestbook.facephoto'/>}</legend>
                <ImageUploadFace
                  index = {props.index}
                  addButtonProps={{
                    color: "rose",
                    round: true
                  }}
                  changeButtonProps={{
                    color: "rose",
                    round: true
                  }}
                  removeButtonProps={{
                    color: "danger",
                    round: true
                  }}
                />
      </GridItem>
    </GridContainer>
  )
}

export default GuestMember