import React, { useContext, useState } from 'react';
import AppContext from '../contexts/AppContext'

import { registGuest, sendVerifyMail } from '../actions/register'

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import { FormattedMessage } from 'react-intl'
import { SET_ACTIVE_STEP } from '../actions'
import { verifyEmail, verifyLength } from 'actions/verifyfuncs'

const useStyles = makeStyles(styles);

export default function RegisterPage() {
  const { state, dispatch }  = useContext(AppContext)
  const [registerEmail, setregisterEmail] = useState(state.guest.email)
  const [registerEmailState, setregisterEmailState] = useState("error")
  const [registerPassword, setregisterPassword] = useState("")
  const [registerPasswordState, setregisterPasswordState] = useState("error")
  const [registerConfirmPassword, setregisterConfirmPassword] = useState("")
  const [registerConfirmPasswordState, setregisterConfirmPasswordState] = useState("error")
  const classes = useStyles()

  const handleRegist = () => {
    switch (guest.status) {
      case 'initial':
        if (registerEmailState === "error") { return; }
        if (registerPasswordState === "error") { return; }
        if (registerConfirmPassword === "error") { return; }
        registGuest(state.guest, dispatch, registerEmail, registerPassword)
        break
      case 'registerd':
        sendVerifyMail(state.guest)
        break
      default:
        msg_id = 'regist_donemsg'
        buttonText_id = 'regist.okbutton'
        dispatch({type: SET_ACTIVE_STEP, step: 1})
        break
    }
  }


  let guest = state.guest
  var msg_id, buttonText_id
  let disabled = true
  switch (guest.status) {
    case 'initial':
      msg_id = 'regist.msg'
      buttonText_id = 'regist.button'
      disabled = false
      break
    case 'registerd':
      msg_id = 'regist.sentmsg'
      buttonText_id = 'regist.resendbutton'
      break
    default:
      msg_id = 'regist.donemsg'
      buttonText_id = 'regist.okbutton'
      break
  }


  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>
              <FormattedMessage id='resgit.title'/>
            </h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5}>
                  <FormattedMessage id={msg_id} values={{
                    guest: guest.guestName,
                    property: guest.roomName,
                    email: guest.email
                  }} />
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  <form className={classes.form}>
                    <CustomInput
                      success={registerEmailState === "success"}
                      error={registerEmailState === "error"}
                      labelText={<FormattedMessage id='regist.email'/>}
                      id="registeremail"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      value={guest.email}
                      inputProps={{
                        defaultValue: guest.email,
                        onChange: event => {
                          if (verifyEmail(event.target.value)) {
                            setregisterEmailState("success");
                          } else {
                            setregisterEmailState("error");
                          }
                          setregisterEmail(event.target.value);
                        },
                        type: "email",
                        disabled: disabled
                      }}
                    />
                    <CustomInput
                      success={registerPasswordState === "success"}
                      error={registerPasswordState === "error"}
                      labelText={<FormattedMessage id='regist.password'/>}
                      id="registerpassword"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: event => {
                          if (verifyLength(event.target.value, 6)) {
                            setregisterPasswordState("success");
                          } else {
                            setregisterPasswordState("error");
                          }
                          setregisterPassword(event.target.value);
                        },
                        type: "password",
                        autoComplete: "off",
                        disabled: disabled
                      }}
                    />
                    <CustomInput
                      success={registerConfirmPasswordState === "success"}
                      error={registerConfirmPasswordState === "error"}
                      labelText={<FormattedMessage id='regist.passwordconf'/>}
                      id="registerconfirmpassword"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                      }}
                      inputProps={{
                        onChange: event => {
                          if (registerPassword === event.target.value) {
                            setregisterConfirmPasswordState("success");
                          } else {
                            setregisterConfirmPasswordState("error");
                          }
                          setregisterConfirmPassword(event.target.value);
                        },
                        type: "password",
                        autoComplete: "off",
                        disabled: disabled
                      }}
                    />
                    <div className={classes.center}>
                      <Button round color="primary" onClick={handleRegist}>
                        <FormattedMessage id={buttonText_id} />
                      </Button>
                    </div>
                  </form>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
